.tab-form {
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &__input {
    border-radius: 15px;
    padding: 12px 20px;
    background: #FFE8B2;
    border: none;
    width: 100%;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant-numeric: lining-nums proportional-nums;

    @media screen and (max-width: 768px) {
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 10px;
    }

    &:focus {
      outline: none;
    }
  }

  &__button {
    width: fit-content;
    margin: auto auto 0;
  }

  &__cross-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: fit-content;
    padding: 0;
  }

  &__add-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    width: 100%;
    border-radius: 15px;
    border: 3px solid #000;
    background: transparent;
    cursor: pointer;
    margin-bottom: 15px;
  }


  &__icon {
    &--plus {
      width: 24px;
      height: 24px;
    }
  }

  &__font {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}