.colored-button {
  $parent: &;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-radius: 66px;
  border: none;
  transition: background-color 0.3s;
  text-decoration: none;
  padding: 16px 40px;
  cursor: pointer;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 16px 32px;
  }

  &__font {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  &:disabled {
    background-color: #4F4F4F;
    color: #D9D9D9;
  }
}