.pages-welcome {
  &__container {
    max-width: 691px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  &__wrapper {
    border-radius: 30px;
    background: #FFF;
    min-height: 335px;
    padding: 42px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      padding: 25px;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__font {
    color: #000;

    &--title {
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 50px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -2.5px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 29px;
      }
    }

    &--subtitle {
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

  }
}