* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Buduj-Sans', sans-serif;
  font-size: 16px;
  line-height: 1;
  background-color: #F2E6D6;

  color: black;

  overscroll-behavior: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.font {
  &--title {
    font-family: Ysabeau, sans-serif;
  }

  &--text{
    font-family: Ysabeau, sans-serif;
  }
}

body[lang="mk"] {
  .font {
    &--title {
      font-family: Ysabeau, sans-serif;
    }

    &--text{
      font-family: Ysabeau, sans-serif;
    }
  }
}

body[lang="tr"], body[lang="el"]{
  .font {
    &--title {
      font-family: Ysebau, sans-serif;
    }

    &--text{
      font-family: Ysabeau, sans-serif;
    }
  }
}

body[lang="el"]{
  .outlined-button__font {
    text-transform: none!important;
  }
}
