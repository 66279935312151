.played-numbers-header {
  display: flex;
  align-items: center;
  padding: 8px 30px;
  border-radius: 10px;
  background: #FFCF99;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }

  &__title {
    flex-shrink: 0;
    margin-right: 20px;
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__number {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #9CBA42;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &__font {
    &--title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    &--number {
      color: #000;
      text-align: center;
      font-style: normal;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-weight: 500;
      line-height: 130%;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}