.question {

  display: flex;
  flex-direction: column;
  padding: 24px 30px 27px;

  @media screen and (max-width: 768px) {
    padding: 13px 17px 18px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__answers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  &__button {
    width: fit-content;
    margin: 25px auto 0;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    &--text {
      color: #000;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    &--answer {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
}