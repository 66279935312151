.player-card {
  width: 150px;
  border-radius: 10px;
  background: #FFF;
  padding: 10px;

  &__name {
    margin-bottom: 10px;
  }

  &__numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 7px;
  }

  &__icon {
    width: 25px;
    height: 25px;
  }

  &__font {
    &--name {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
    }

    &--number {
      color: #000;
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }
  }
}